<script>
import {defineComponent} from "vue";
import headtip from "@/views/headTip.vue";
import consultTip from "@/components/consultTip.vue";

export default defineComponent({
  components: {consultTip, headtip},
  data(){
    return{
      dialogVisible: false,
      title: 'GPT Assistants',
    }

  },
  methods:{
    clickConsult(item) {

      console.log(item)
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },

  }
})
</script>

<template>
  <div class="assistantsContainer">
    <div class="header" style="padding:1.5vh 5vw;background-color: #f2f1f4">
      <headtip @showDialog="showPartner"></headtip>
    </div>


    <div class="title1">GPT Assistants</div>
    <div class="title2" style="margin: 0 7vw">Link to mainstream AI tool models, allowing you to reach them with one click.</div>
    <div style="width: 100%;height: 2vw;;margin: 1vh 7vw 3vh">
      <div class="title2" style="display: flex;align-items: center;margin: auto">
        <img src="@/assets/kefu3.png" style="height: 4vw;width: 4vw">
        <span style="color:#f97420;">For customized usage training and development,</span>

      </div>
      <div style="background-color: #1b1e76;color: white;padding: 0.1vh 0.5vw;margin-top: 0.8vh;border-radius: 9px;cursor: pointer;width: fit-content" @click="clickConsult('Office GPTs')" >Contact Us</div>
    </div>
    <div class="assistants" style="display: grid;grid-template-columns: minmax(0,1fr) ;padding: 0 5vw;margin-top: 6vh">
      <a href="https://www.deepseek.com/" target="_blank">
        <div class="assistant" >
          <img  src="@/assets/deepseek.png">
          <div class="contentDesign">
            <div class="t1">DeepSeek</div>
            <div class="t2">DeepSeek is a Hangzhou-based AI firm specializing in Large Language Models (LLMs) using deep learning.

            </div>
          </div>
        </div>
      </a>
      <a href="https://openai.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/openai.png">
          <div class="contentDesign">
            <div class="t1">OpenAi</div>
            <div class="t2">OpenAI is an AI research and development company aiming to advance artificial intelligence for the benefit of humanity.

            </div>
          </div>
        </div>
      </a>
      <a href="https://www.midjourney.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/MidJourney-copy.png">
          <div class="contentDesign">
            <div class="t1">Midjourney</div>
            <div class="t2">Midjourney is an AI-driven art generation tool that helps users create unique and captivating visual artworks.

            </div>
          </div>
        </div>
      </a>
      <a href="https://chatglm.cn/?lang=en" target="_blank">
        <div class="assistant">
          <img  src="@/assets/zhi.png">
          <div class="contentDesign">
            <div class="t1">ChatGLM</div>
            <div class="t2">ChatGLM is a powerful language model for conversational AI, capable of engaging in intelligent and informative dialogues.
            </div>
          </div>
        </div>
      </a>
      <a href="https://lumen5.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/Lumen5-PrimaryLogo.png">
          <div class="contentDesign">
            <div class="t1">Lumen</div>
            <div class="t2">Lumen is an innovative platform for building high-performance, scalable, and secure serverless applications.
            </div>
          </div>
        </div>
      </a>
      <a href="https://flair.ai/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/flair.png">
          <div class="contentDesign">
            <div class="t1">Flair</div>
            <div class="t2">Flair is a library for building state-of-the-art natural language processing (NLP) models with a focus on ease of use and flexibility.

            </div>
          </div>
        </div>
      </a>
      <a href="https://gamma.app/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/gamma.png">
          <div class="contentDesign">
            <div class="t1">Gamma</div>
            <div class="t2">Gamma is an advanced AI platform designed to optimize decision-making processes through intelligent data analysis and prediction.

            </div>
          </div>
        </div>
      </a>
      <a href="https://www.cursor.com" target="_blank">
        <div class="assistant">
          <img  src="@/assets/placeholder-logo.webp">
          <div class="contentDesign">
            <div class="t1">Cursor</div>
            <div class="t2">Cursor is an AI - integrated platform , which focuses on revolutionizing software development by leveraging AI capabilities.

            </div>
          </div>
        </div>
      </a>

    </div>
    <consultTip
        :thisTitle="title"
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>
</template>

<style scoped lang="scss">
.assistantsContainer{
  width: 100%;
  .topback {
    position: fixed;
    z-index: 9;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .title1{
    font-size: 6vw;
    margin: 1vh  auto auto;
    padding: 2vh 0;
    text-align: center;
    font-weight: bolder;
  }
  .title2{
    font-size: 4vw;
    text-align: left;
  }
  .assistant{
    margin: 1vh 2vh;
    padding: 2vh;
    border-radius: 9px;
    //padding: 4vh 6vw 4vh 2vw;
    display: flex;
    align-items: center;
    background-color: white;
    //border: 1px solid gray;
    img{
      width: 12vw;
      height: 12vw;
    }
    .contentDesign{
      margin-left: 4vw;
      .t1{
        font-size: 4vw;
        font-weight: bolder;
      }
      .t2{
        margin-top: 1vh;
        font-size: 3vw;
      }
    }
  }
}

</style>
