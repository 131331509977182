<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1">
          <div class="talk_con">
            <div>
              <div class="title">User Feedback </div>
              <div class="title1">Dear Users:</div>
              <div class="title2" >
                We strive to enhance your online experience and would deeply appreciate your feedback. Each suggestion or comment you provide is vital <span  style="color: rgb(255, 100, 0);font-weight: bold">for our ongoing development</span>. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!</div>
            </div>
            <div class="form-container">
              <el-row style="width: 100%">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="30%">
                  <el-form-item style="margin-bottom: 50px;background-color: #e8edf5" label="Type of Issue" prop="type">
                    <div style="margin:auto;display: flex;justify-content: center">
                      <el-radio v-model="ruleForm.type" label="1">Program Bug</el-radio>
                      <el-radio v-model="ruleForm.type" label="2">Function suggestions   </el-radio>
                      <el-radio v-model="ruleForm.type" label="3">Content Feedback </el-radio>
                      <el-radio v-model="ruleForm.type" label="4">Others</el-radio>
                    </div>
                  </el-form-item>

<!--                  <el-form-item style="background-color: #f5f7fa;height: 25vh" label="您的意见或建议"  prop="suggestion">-->

<!--                    <el-input type="textarea" style="resize: none;" v-model="ruleForm.suggestion" placeholder="请描述您的建议或遇到的问题，如需联系客服可访问首页在线客服-在线咨询，谢谢! (15-200字)"></el-input>-->

<!--                  </el-form-item>-->
                  <el-form-item style="background-color: #f5f7fa;height: 25vh ;display: flex"  label="Your Opinions or Suggestions" prop="suggestion">
                    <el-input :rows="3" type="textarea" id="text"  @input="resizeTextarea" style="resize: none;" onfocus="this.placeholder=''" onblur="this.placeholder='Please describe any problems or suggestions you have. For customer service inquiries, kindly use our Customer service Onilne chat, Thank you!(15-200 words)'" v-model="ruleForm.suggestion" placeholder="Please describe any problems or suggestions you have. For customer service inquiries, kindly use our Customer service Onilne chat, Thank you!(15-200 words)"></el-input>
                  </el-form-item>

                  <el-form-item  style="background-color: #e8edf5;display: flex"  label="Screenshots or attachment of the Issue" prop="attachment">
                    <el-upload
                        ref="field102"
                        :file-list="fileList"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :before-upload="fileUpload"
                        class="inputStyle"
                        :show-file-list="true"
                        action="/file/upload"
                        multiple>
                      <div style="display: flex;margin-left: 15px">
                        <div><i class="el-icon-upload"></i></div>
                        <div class="el-upload__text" style="margin-left: 10px"><em>
                          （Please upload, if applicable)</em></div>
                      </div>
                    </el-upload>
                  </el-form-item>

                  <el-form-item label="Your Name" prop="user_name">
                    <el-input class="yourname" type="textarea" rows="1" v-model="ruleForm.user_name" onfocus="this.placeholder=''" onblur="this.placeholder='please enter your name'" placeholder="please enter your name"></el-input>
                  </el-form-item>
                  <el-form-item style="background-color: #e8edf5"  label="Contact Details（Phone/mail）" prop="mail">
                    <el-input  class="yourphone" type="textarea" rows="1"  v-model="ruleForm.mail" onfocus="this.placeholder=''" onblur="this.placeholder='Please be assured that your contact information will be kept confidential and used solely '" placeholder="Please be assured that your contact information will be kept confidential and used solely "></el-input>
                  </el-form-item>

                  <el-form-item style="text-align: center">

                  </el-form-item>
                </el-form>
              </el-row>
              <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
              </div>
            </div>
          </div>


          <bottomTip></bottomTip>
        </div>
        <successTip
            :dialogVisible="dialog"
            @hideDialog="hidesuccess"
        ></successTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
      <div v-if="showModal" @click="closeModal" class="modal-overlay">
        <div class="modal-content" >
          <img :src="largeSrc" alt="Large Image" class="large-image">
        </div>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div>
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
          </div>
          <div class="makeHole1">
            <div class="talk_con">
              <div>
                <div class="title">User Feedback </div>
                <div class="title1">Dear Users:</div>
                <div class="title2" >
                  We strive to enhance your online experience and would deeply appreciate your feedback. Each suggestion or comment you provide is vital <span  style="color: rgb(255, 100, 0);font-weight: bold">for our ongoing development</span>. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!</div>
              </div>
              <div class="form-container">
                <el-row style="width: 100%">
                  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="40%">
                    <el-form-item style="background-color: #e8edf5;margin-bottom: 50px" label="
          Type of
          Issues
" prop="type" >
                      <div style="margin-left: 15px">
                        <el-radio v-model="ruleForm.type" label="1">Program Bug</el-radio>
                        <el-radio v-model="ruleForm.type" label="4">Others</el-radio>
                        <el-radio v-model="ruleForm.type" label="2">Function suggestions   </el-radio>
                        <el-radio v-model="ruleForm.type" label="3">Content Feedback </el-radio>
                      </div>
                    </el-form-item>

                    <el-form-item  label="Suggestions" prop="suggestion">

                      <el-input :rows="8" type="textarea" v-model="ruleForm.suggestion" placeholder="Please describe any problems or suggestions you have. For customer service inquiries, kindly use our Customer service Onilne chat, Thank you!(15-200 words)"></el-input>

                    </el-form-item>

                    <el-form-item  style="background-color: #e8edf5"  label="Attachments" prop="attachment">
                      <el-upload
                          ref="field102"
                          :file-list="fileList"
                          :on-preview="handlePreview"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          :before-upload="fileUpload"
                          class="inputStyle"
                          :show-file-list="true"
                          action="/file/upload"
                          multiple>
                        <div style="display: flex;margin-left: 15px">
                          <div><i class="el-icon-upload"></i></div>
                          <div class="el-upload__text" style=""><em>
                            （Please upload, if applicable)</em></div>
                        </div>
                      </el-upload>
                    </el-form-item>

                    <el-form-item label="Your Name" prop="user_name">
                      <el-input  v-model="ruleForm.user_name" placeholder="please enter your name"></el-input>
                    </el-form-item>
                    <el-form-item style="background-color: #e8edf5"  label="Contact Details" prop="mail">
                      <el-input  rows="4" type="textarea" v-model="ruleForm.mail" placeholder="Please be assured that your contact information will be kept confidential and used solely for internal reference"></el-input>
                    </el-form-item>

                    <el-form-item style="text-align: center">

                    </el-form-item>
                  </el-form>
                </el-row>
                <div style="text-align: center" class="submitStyle" @click="submitForm(ruleForm)">Submit
                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <successTip
            :dialogVisible="dialog"
            @hideDialog="hidesuccess"
        ></successTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import successTip from "@/components/successTip.vue";
export default {

  name: "",

  props: [],

  components: {successTip, PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      changeVisable:1,
      showModal: false,
      largeSrc:'',
      title:'',
      pageTitle: 'User Feedback - Oil & Gas GPT',
      pageDescription: '您好！为了给您提供更好的网上体验，我们诚挚邀请您反馈使用感受，您留下的每个意见或建议都是我们不断改进的动力！我们会针对您的反馈不断优化网页，为您提供更加愉悦的服务体验！感谢您的支持！',
      pageKeywords: '智慧油气，基础设施建设，智慧油气基础设施建设，井口一体化远传系统，远程调压解决方案，橇装机柜间整体解决方案，数字化抽油机控制柜，安迅智云，语音机器人助理，安迅数智，油气通GPT',
      model:false,
      scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      dialog:false,
      contentType: {
        phone: "",
        mail: "",
        address: "",
      },
      field102Action: 'http://54.223.240.188:8787',
      fileList: [],
      ruleForm: {
        title:'油气通意见反馈',
        type: '',
        suggestion: '',
        attachment:'',
        user_name:'',
        mail:'',
        phone:''
      },
      rules: {

        suggestion:[
          // {required: true, message: 'Please describe any problems or suggestions you have.', trigger: 'blur'}
        ]

      }

    }

  },


  methods: {
    resizeTextarea() {
      const textarea = document.getElementById('text');
      if (textarea === null) {
        console.error('没有找到id为text的textarea元素');
        return;
      }
      const computedStyle = window.getComputedStyle(textarea);
      const height = computedStyle.height;
      const height1 = parseInt(height, 10);
      console.log("height",height)
      console.log("height",height1)


      const clientheight = window.innerHeight * 0.2;
      if (isNaN(height1) || height1 < clientheight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
        if (this.newMessage.trim() === '') {
          textarea.style.height = '22px';
        }
        textarea.scrollTop = 0;
      }
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    GetColumnContact() {
      this.$axios.get('/api/feedback/add', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token,
        }
      })
          .then((res) => {
            this.contentType = res.data;
          })
          .catch(() => {
          });
    },

    handleClose() {
      this.ruleForm.type =""
      this.ruleForm.suggestion =""
      this.ruleForm.attachment=""
      this.ruleForm.user_name=""
      this.ruleForm.mail=""
      // this.$emit('hideDialog')
    },
    submitForm(formName) {
      formName.consultUrl = window.location.href

      console.log(formName)

      if (formName.suggestion === "") {
        this.$message('Please describe any problems or suggestions you have.');
      } else {
        // console.log("mail",formName.mail)
        if(!formName.mail.includes('@')){
          // formName.phone=formName.mail
          // formName.mail=''
        }
        this.$axios.post("/searchCN/addWebFeedback",formName, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token
          },
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.dialog = true
                this.handleClose()
                // this.resetForm(formName)
              } else {
                this.$message('提交失败，请重新提交试试');
              }

            }).catch(error => {
          console.log("errorerrorerrorerror:::",error)
        })

      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    // 文件上传
    async fileUpload(file) {
      try {
        // 构建表单数据
        const formData = new FormData();
        formData.append('file', file);

        // 向后端发送文件到 /common/upload 接口
        const response = await this.$axios.post('/file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.uploadedFileValue = response.data;
        console.log(response.data); // 打印响应数据到控制台
        this.ruleForm.attachment=response.data.msg
        // 返回 true 允许上传，false 禁止上传
        return true;
      } catch (error) {
        console.error('上传失败:', error);
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("预览")
      console.log(file);
      // this.showModal = true;
      window.open(file.response.url)
      // this.largeSrc=file.response.url
    },

    beforeRemove(file, fileList) {
      console.log(fileList)
      return this.$confirm(`确定移除 ${ file.name }？`);
    },


    field102BeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      return isRightSize
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hidesuccess(){
      this.dialog = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'User Feedback -油气通GPT',
        pageDescription: '您好！为了给您提供更好的网上体验，我们诚挚邀请您反馈使用感受，您留下的每个意见或建议都是我们不断改进的动力！我们会针对您的反馈不断优化网页，为您提供更加愉悦的服务体验！感谢您的支持！',
        pageKeywords: 'User Feedback ，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.yourname{

  ::v-deep .el-textarea__inner{
    //background-color: #f1f3f6 !important;
    background-color: #fdfdfd !important;
  }
}
.yourphone{

  ::v-deep .el-textarea__inner{
    background-color: #e7ecf3 !important;
  }
}
.totalmakeHole{
  background: white;
  overflow-x: hidden;
  width: 99vw;
  height: auto;
  position: relative;
  //overflow-y: hidden;
  .topback{
    position: fixed;
    z-index:1;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: fit-content;

    ::v-deep .el-upload-list__item:first-child{
      margin:0 0 0 -1.5px;
      padding-left: 10px;
      border-left: 2px solid white;
    }
    ::v-deep .el-upload-list__item{
      margin:0 0 0 -1.5px;
      padding-left: 10px;
      border-left: 2px solid white;
      border-radius: 0;
    }
    ::v-deep .el-form-item {
      display: flex;
      margin-bottom: 0 !important;
      border-top:2px solid white;
    }
    ::v-deep .el-form-item__error {
      left: 15px !important;
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
    ::v-deep {
      .el-dialog__body {
        padding: 30px 50px 30px 35px;
        //color: #606266;
        font-size: 14px;
        word-break: break-all;
      }

      .el-dialog__header {
        padding: 10px;
        .el-dialog__title {
          font-weight: bold;
          font-size: 14px;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #2F6EFF;;
        border: #2F6EFF 1px solid;
        border-radius: 50%;
      }
      .el-form-item__content{
        margin-left: 0 !important;
        width: 70%;
        display: flex;
        align-items: center;
        //justify-content: center;
        font-size: 0.9vw;
        line-height: 22px;
        border-left: white 2px solid;
      }
      .el-input__inner {
        font-size: 0.8vw;
        white-space: pre-line;
        font-family: 'Times New Roman';

        border-radius: 5px !important;
        border: none !important;
        //height: 30px;
        background-color: transparent;

      }
      .el-input__inner::placeholder{
        text-align: left;
      }
      .el-textarea__inner::placeholder{
        //color: blue !important;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        //height: 25vh;
        text-align: left;
        //padding-top: 11vh;

        //line-height: 12vh;
      }
      .el-textarea__inner{
        //min-height:25vh !important;
        resize: none;
        background-color: #f3f5f8;
      }
      .el-textarea {
        display: flex;
        align-items: center;
        line-height: 35px;
        border-radius: 5px !important;
        //border: 1px solid #9b9a9a !important;
        position: relative;
        font-size: 14px;
      } .el-textarea__inner {
                //height: fit-content !important;
                font-size: 0.7vw;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                border: none;
        }

      .el-form-item__label {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9vw;
        height: 100%;
        text-align: center;
        border-right: 2px solid white;
        margin:auto 0;
        //font-size: 0.9vw;
        //text-align: center;
        //border-right: 2px solid white;
      }
      .el-avatar, .el-cascader-panel, .el-radio, .el-radio--medium.is-bordered .el-radio__label, .el-radio__label{
        font-size: 0.9vw;
      }

      .el-button {
        width: 150px;
        height: 45px;
        line-height: 35px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        color: #2F6EFF;
        padding: 0;

        a {
          display: block;
        }
      }

      .el-button--primary {
        border: 1px solid #2F6EFF;
        background: #2F6EFF;
        color: #fff;
      }

    }
    .talk_con {
      height: auto;
      width: 65vw;
      padding:0vh 5vw 3vh 5vw;
      margin: 10vh auto;
      border-radius: 5px;
      //box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
      background-color: #fff;
      .title{
        margin:0 auto 3vh auto;
        text-align: center;
        font-size:1.5vw;
        color:#333333;
        font-weight: bold
      }
      .title1{
        margin:0 auto 2vh auto;
        text-align: left;
        font-size:1vw;
        color:#333333;
        font-weight: bold
      }
      .title2{
        margin:0 auto 4vh auto;
        text-align: left;
        line-height: 4vh;
        font-size:0.9vw;
        color:#333333;
        font-weight: normal;
      }

    }
    .submitStyle{
      cursor: pointer;
      width: 100%;
      height: 35px;
      display: flex;
      justify-content:  center;;
      align-items: center;
      font-size: 0.9vw;
      font-weight: bold;
      background-color: #cdd9ea
    }
    .submitStyle:hover{
      //font-weight: bold;
      background-color: #efdec3
    }
  }

}
.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}
.totalmakeHoleModel{
  //background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .screen1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 0;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    /* Add any additional styling as needed */
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: fit-content;
    .talk_con {
      height: auto;
      width:90%;
      padding:15px;
      margin: 5px auto 15px auto;
      border-radius: 5px;
      //box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
      //background-color: #fff;
      .title{
        margin:0 auto 20px auto;
        text-align: center;
        font-size:16px;
        color:#333333;
        font-weight: bold
      }
      .title1{
        margin:0 auto 10px auto;
        text-align: left;
        font-size:16px;
        color:#333333;
        font-weight: bold
      }
      .title2{
        margin:0 auto 15px auto;
        text-align: left;
        line-height: 26px;
        font-size:14px;
        color:#333333;
        font-weight: normal;
      }

    }
    .submitStyle{
      cursor: pointer;
      width: 100%;
      height: 35px;
      display: flex;
      justify-content:  center;;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      background-color: #e8edf5
    }
    .submitStyle:hover{
      //font-weight: bold;
      background-color: #efdec3
    } ::v-deep .el-form-item {
        margin-bottom: 0 !important;
        border-top:2px solid white;
      }
    ::v-deep .el-form-item__error {
      left: 15px !important;
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
    }


    ::v-deep {
      .el-dialog__body {
        padding: 30px 50px 30px 35px;
        //color: #606266;
        font-size: 14px;
        word-break: break-all;
      }

      .el-dialog__header {
        .el-dialog__title {
          font-weight: bold;
          font-size: 14px;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #2F6EFF;;
        border: #2F6EFF 1px solid;
        border-radius: 50%;
      }

      .el-input__inner {
        font-size: 12px;
        font-family: 'Times New Roman';

        border-radius: 5px !important;
        border: none !important;
        background-color: transparent;

      }

      .el-textarea {
        line-height: 35px;
        border-radius: 5px !important;
        //border: 1px solid #9b9a9a !important;
        position: relative;
        font-size: 14px;
      }

      .el-textarea__inner {

        background-color: #f5f5f5;
        font-size: 12px;
        border-radius: 0;
        border: none;
        font-family: 'Times New Roman';

      }
      .el-form-item__content{
        font-size: 12px;
        line-height: 20px;
        border-left: white 2px solid;
      }
      .el-upload{
        text-align: left;
      }
      .el-form-item__label {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        //border-right: 2px solid white;
        white-space: pre-line;
        padding: 15px 5px;
        /*text-align-last: justify;*/
        /*text-align:justify;*/
        //margin-top:-4px;
        //line-height:25px;
        //text-justify:distribute-all-lines;

      }

      .el-button {
        width: 150px;
        height: 45px;
        line-height: 35px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        color: #2F6EFF;
        padding: 0;

        a {
          display: block;
        }
      }

      .el-button--primary {
        border: 1px solid #2F6EFF;
        background: #2F6EFF;
        color: #fff;
      }
    }
  }
  ::v-deep .el-upload-list__item:first-child{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
  }
  ::v-deep .el-radio__label{
    padding-left: 5px;
    font-size: 12px;
    margin-right: 15px;
  }
  ::v-deep .el-radio{
    margin-right: 0;
  }
  ::v-deep .el-upload-list__item{
    margin:0 0 0 -1.5px;
    padding-left: 10px;
    border-left: 2px solid white;
    border-radius: 0;
  }
}
</style>
